import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Productos</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Desarrollo Web</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Nuestro servicio de desarrollo web ofrece soluciones personalizadas y escalables que mejoran la presencia digital de tu negocio, optimizan la experiencia del usuario y fomentan el crecimiento y la rentabilidad.
                                    Con capacidades como diseño responsive, integración de comercio electrónico y desarrollo de aplicaciones web, gestionamos todos los aspectos del desarrollo para garantizar que tu sitio web sea atractivo y funcional.
                                    Almacenamos datos de manera segura en la nube, asegurando que tu sitio sea accesible y rápido desde cualquier dispositivo y ubicación.
                                </p>
                                {/*<div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>*/}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Desarrollo de aplicaciones multiplataforma</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Nuestro servicio de desarrollo de aplicaciones multiplataforma crea soluciones robustas y adaptables que amplían el alcance de tu negocio, mejorando la experiencia del usuario en múltiples dispositivos y sistemas operativos.
                                    Con funciones como diseño intuitivo, integración de APIs y soporte para iOS y Android, desarrollamos aplicaciones que son eficientes y atractivas, cubriendo todas tus necesidades empresariales.
                                    Utilizamos tecnología en la nube para garantizar que tus aplicaciones sean seguras, accesibles y ofrezcan un rendimiento óptimo en cualquier entorno.
                                </p>
                               {/* <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>*/}
                                
                                {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Consultoría</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Nuestro servicio de consultoría proporciona estrategias y soluciones personalizadas para mejorar la eficiencia y competitividad de tu negocio. Ofrecemos análisis detallados, planificación estratégica y asesoramiento experto en diversas áreas, incluyendo tecnología, marketing y operaciones.
                                    Trabajamos en estrecha colaboración contigo para identificar oportunidades de mejora y desarrollar un plan de acción que impulse el crecimiento y el éxito de tu empresa.
                                </p>
                                {/*<div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>*/}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Tiendas Online</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Nuestro servicio de desarrollo de tiendas online ofrece soluciones completas y personalizadas para llevar tu negocio al mundo digital. Desde el diseño y la implementación hasta la optimización y el soporte, nos encargamos de crear una tienda online atractiva, segura y fácil de usar.
                                    Integramos plataformas de pago, gestión de inventarios y herramientas de marketing digital para maximizar tus ventas y mejorar la experiencia de tus clientes.
                                </p>
                                {/*<div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>*/}
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;